




















































import Vue from 'vue';
import { Task } from '../../types/Task';
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

interface Data {
  dates?: { start: Date; end: Date };
}

export default Vue.extend({
  name: 'modal-task-due-date',
  components: {
    VDatePicker
  },
  data(): Data {
    return {
      dates: undefined
    };
  },
  computed: {
    task(): Task {
      return this.$store.getters['tasks/getCurrentTask'] as Task;
    },
    datePickerAttrs() {
      return {
        attributes: [
          {
            key: 'today',
            dot: 'orange',
            popover: {
              label: 'Today',
              fillMode: 'light'
            },
            dates: new Date()
          }
        ],
        theme: {
          container: {
            light: 'vc-text-gray-900 vc-bg-white'
          }
        }
      };
    },
    isSameDates(): boolean {
      if (!this.dates) return false;
      const isSameDate = (taskDate: string, localDate?: Date) =>
        new Date(taskDate).getTime() === new Date(localDate || 0).getTime();
      const isSameStart = isSameDate(this.task.startAt, this.dates.start);
      const isSameEnd = isSameDate(this.task.endAt, this.dates.end);
      return isSameStart && isSameEnd;
    },
    isMoreOneDay(): boolean {
      if (!this.dates) return false;
      const { start, end } = this.dates;
      return end.getTime() - start.getTime() !== 0;
    }
  },
  watch: {
    task: {
      handler(val: Task) {
        const start = val.startAt;
        const end = val.endAt;
        if (start && end) {
          this.dates = { start: new Date(start), end: new Date(end) };
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.dates?.end) {
      const calendar = this.$refs.calendar as HTMLFormElement;
      calendar.$refs.calendar.showPageRange(this.dates.end);
    }
  },
  methods: {
    close() {
      this.$modal.hide('modal-task-due-date');
    },
    datesPick() {
      if (!this.isSameDates) this.$emit('dates-pick', this.dates);
      this.close();
    }
  }
});
